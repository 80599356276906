<!--分享页 -->
<template>
  <div class="share_box">
    <el-dialog
      :title="$t('label.share_form')"
      :visible.sync="shareDialog"
      width="800px"
      custom-class="dialog"
      :close-on-click-modal="false"
    >
      <div class="sharecontent">
        <el-tabs v-model="shareName" @tab-click="handleClicked">
          <el-tab-pane
            :label="$t('label.share_link')"
            name="first"
          ></el-tab-pane>
          <el-tab-pane
            :label="$t('label.embed_code')"
            name="second"
          ></el-tab-pane>
          <el-tab-pane :label="$t('label.qrcode')" name="third"></el-tab-pane>
        </el-tabs>
        <div class="Slink" v-show="firstShow">
          <p>
            <!-- 您的表单已安全发布，可以在以下网址中使用，将您的表单作为公共链接共享。 -->
            {{ $t("c198") }}
          </p>
          <!-- 表单链接 -->
          <p>{{ $t("c199") }}</p>
          <div class="slinkcontent">
            <input type="text" v-model="shareurl" disabled/>
            <button @click="copyText(shareurl)">
              {{ $t("pagecreator.page.button.copy") }}
            </button>
          </div>
          <p>
            <!-- 在社交媒体上分享 -->
            {{ $t("c200") }}
            <!-- {{ $t("label.preciew_tab") }}
            <svg class="icon" aria-hidden="true">
              <use href="#icon-share"></use>
            </svg> -->
          </p>
          <!-- 在您的社交媒体上分享此表格 -->
          <p>{{ $t("c201") }}</p>
          <div class="svg_box">
            <svg aria-hidden="true" @click="svgBtn">
              <use href="#icon-facebook_white"></use>
            </svg>
            <svg aria-hidden="true" @click="svgBtn">
              <use href="#icon-twitter_white"></use>
            </svg>
            <svg aria-hidden="true" @click="svgBtn">
              <use href="#icon-linkedIn_white"></use>
            </svg>
          </div>
          <!-- 在Mailchimp中分享 -->
          <p>{{ $t("c202") }}</p>
          <!-- 通过Mailchimp分享您的表单 -->
          <p>{{ $t("c203") }}</p>
          <div class="mailchimp">
            <img
              src="@/assets/images/marketing/mailchimp.svg"
              @click="mailchimpFun"
            />
          </div>
        </div>
        <div class="embcode" v-show="secondShow">
          <p>
            {{ $t("label.embed_website") }}
            <!-- <span
              >{{ $t("page.404.button.toknowmore") }}
              <svg class="icon" aria-hidden="true">
                <use href="#icon-share"></use>
              </svg>
            </span> -->
          </p>
          <div class="sourcecode">
            <div class="embcontent">
              <p>{{ vhtml }}</p>
            </div>
          </div>
        </div>

        <div class="QRcode" v-show="thirdShow">
          <div class="left_box">
            <!-- 下载二维码 -->
            <p>{{ $t("c205") }}</p>
            <!-- PNG格式 -->
            <p>{{ $t("c206") }}</p>
            <div class="png_box">
              <dl :key="index" v-for="(val, index) in pngArr">
                <a :href="val.download">
                  <dt>
                    <svg aria-hidden="true">
                      <use href="#icon-downArrow"></use>
                    </svg>
                  </dt>
                  <dd>
                    <span>{{ val.name }}</span>
                    <span>{{ val.value }}</span>
                  </dd>
                </a>
              </dl>
            </div>
            <!-- 矢量格式 -->
            <p>{{ $t("c207") }}</p>
            <div class="png_box">
              <dl
                :key="index"
                v-for="(val, index) in vectorArr"
                @click="vectorFun(index)"
              >
                <a>
                  <dt>
                    <svg aria-hidden="true">
                      <use href="#icon-downArrow"></use>
                    </svg>
                  </dt>
                  <dd>
                    <span style="margin-top: 8px">{{ val.name }}</span>
                  </dd>
                </a>
              </dl>
            </div>
            <!-- 打包下载 -->
            <p>{{ $t("c208") }}</p>
            <div class="png_box">
              <dl
                :key="index"
                v-for="(val, index) in packArr"
                style="border: 0"
                @click="allFun('all')"
              >
                <a>
                  <dt>
                    <svg aria-hidden="true">
                      <use href="#icon-downArrow"></use>
                    </svg>
                  </dt>
                  <dd style="display: flex;align-items: center;">
                    <span>{{ val.name }}</span>
                  </dd>
                </a>
              </dl>
            </div>
          </div>
          <img :src="qrcodeUrl" style="width: 150px; margin-right: 30px" />
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="shareDialog = false">{{
          $t("label.emailsync.button.cancel")
        }}</el-button>
        <el-button type="primary" @click="copyText(vhtml)" v-show="secondShow">
          {{ $t("pagecreator.page.button.copy") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import * as marketChannel from "../api.js"; //api
import { copyText } from "@/utils/copyInfo";
import axios from "@/config/httpConfig";

export default {
  data() {
    return {
      shareDialog: false,
      vhtml: "",
      qrcodeUrl: "",
      shareurl: "",
      shareName: "first", //share弹框中的切换
      firstShow: true, //share弹框 Embed code开关
      secondShow: false, //share弹框Share link开关
      thirdShow: false, //share弹框QR code开关
      pngArr: [
        // "大尺寸" "中等尺寸" "小尺寸"
        {
          name: this.$i18n.t("c210"),
          value: "1000*1000px",
          download: "",
        },
        {
          name: this.$i18n.t("c209"),
          value: "500*500px",
          download: "",
        },
        {
          name: this.$i18n.t("c211"),
          value: "300*300px",
          download: "",
        },
      ],
      vectorArr: [
        // "PDF格式","SVG格式", "EPS格式",
        {
          name: this.$i18n.t("c323"),
        },
        {
          name: this.$i18n.t("c212"),
        },
        {
          name: this.$i18n.t("c213"),
        },
      ],
      packArr: [
        // c214"包含三种尺寸的位图和所有格式的矢量图",
        {
          name: this.$i18n.t("c214"),
        },
      ],
      checkedid:''
    };
  },
  mounted() {
  },
  methods: {
    init() {
      this.shareName = "first";
      this.firstShow = true;
      this.secondShow = false;
      this.thirdShow = false;
      let id = this.checkedid?this.checkedid:this.$store.state.creatform.itemDataId
      
      marketChannel
        .getShareInfo({ id: id })
        .then((res) => {
          this.shareurl = res.data.url;
          this.qrcodeUrl = res.data.qrcodeUrl;
          this.vhtml = res.data.pagecontent;
          this.pngArr[0].download = res.data.download1000;
          this.pngArr[1].download = res.data.download500;
          this.pngArr[2].download = res.data.download300;
        })
        .catch(() => {});
    },
    vectorFun(val) {
      if (val == "0") {
        this.GetSharePhoto("pdf");
      } else if (val == "1") {
        this.GetSharePhoto("svg");
      } else {
        this.GetSharePhoto("eps");
      }
    },
    //下载全部三种类型
    allFun(val) {
      this.GetSharePhoto(val);
    },
    //下载各个文件格式
    GetSharePhoto(val) {
      let obj = {
        shareurl: this.qrcodeUrl,
        sharephototype: val,
      };
      let app = JSON.stringify(obj);
      axios
        .post(`form/getSharePhoto`, app, {
          responseType: "blob",
        })
        .then((res) => {
          if (val == "all") {
            this.typeFun("zip", res);
          } else {
            this.typeFun(val, res);
          }
        });
    },
    //下载各个格式的文件
    typeFun(val, res) {
      let blob = new Blob([res]); //type这里表示xlsx类型
      let downloadElement = document.createElement("a");
      let href = window.URL.createObjectURL(blob); //创建下载的链接
      downloadElement.href = href;
      downloadElement.download = "exportform." + val; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    },
    //切换二维码、代码复制、链接分享
    handleClicked(tab) {
      if (tab.index === "0") {
        this.firstShow = true;
        this.secondShow = false;
        this.thirdShow = false;
      } else if (tab.index === "1") {
        this.firstShow = false;
        this.secondShow = true;
        this.thirdShow = false;
      } else {
        this.firstShow = false;
        this.secondShow = false;
        this.thirdShow = true;
      }
    },
    copyText,
    // 获取MailChimp权限
    mailchimpFun() {
      marketChannel
        .getMailChimpPermission()
        .then((res) => {
          if (res.data) {
            this.$router.push({
              path: `/marketForm/mailchimpList/list`,
              query: {
                shareurl: this.shareurl,
              },
            });
          } else {
            this.$router.push({
              path: `/marketForm/mailchimpList/authentication`,
              query: {
                shareurl: this.shareurl,
              },
            });
          }
        })
        .catch(() => {});
    },
    // 跳转社交媒体
    svgBtn() {
      this.$router.push({
        path: `/social/social`,
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.share_box {
  ::v-deep .el-dialog__header {
    height: 65px;
    background: linear-gradient(90deg, #2d6cfc 0%, #6c98fd 100%);
    span {
      color: #fff;
      font-size: 16px;
      line-height: 28px;
      padding-left: 20px;
    }
    i {
      color: #fff;
      font-size: 26px;
      padding: 0 20px 0 0;
    }
  }
  ::v-deep .el-dialog__body {
    padding: 0 !important;
    .sharecontent {
      width: 800px;
      .el-tabs,
      .el-tabs__nav {
        width: 800px;
        display: flex;
        .el-tabs__item {
          flex: 1;
          // width: 264px;
          text-align: center;
          padding: 0;
          border: 1px solid #dcdcdc !important;
          .is-top{
            border: 1px solid #dcdcdc !important;
          }
        }
        .is-active {
          background: #f4f8ff;
          color: #303133;
        }
      }
      #tab-first,
      #tab-second {
        border: 1px solid #dcdcdc !important;
      }
      .el-tabs__active-bar {
        width: 267px !important;
        background-color: #dcdcdc;
      }
      .sharehead {
        width: 100%;
        height: 111px;
        background: #fef8f0;
        border: 1px solid #ffdfbb;
        border-radius: 1px;
        padding-left: 20px;
        h1 {
          font-size: 14px;
          color: #333333;
          letter-spacing: 0;
          font-weight: bolder;
          line-height: 35px;
          span {
            font-size: 14px;
            color: #151515;
            letter-spacing: 0;
            line-height: 19px;
            font-weight: normal;
          }
        }
      }
      .embcode {
        width: 100%;
        padding: 20px 20px 20px 20px;
        box-sizing: border-box;
        p {
          font-size: 14px;
          color: #080707;
          letter-spacing: 0;
          line-height: 19px;
          margin-bottom: 15px;
          span {
            font-size: 14px;
            color: #2D6CFC;
            letter-spacing: 0;
            line-height: 19px;
            .icon {
              display: inline-block;
              width: 12px;
              height: 14px;
            }
          }
        }
        .sourcecode {
          width: 100%;
          height: 300px;
          overflow: auto;
          overflow-x: hidden;
        }
        .embcontent {
          width: 100%;
          height: 300px;
          border: 1px solid #dedcda;
          p {
            background: rgb(157, 219, 209);
          }
        }
      }
      .Slink {
        width: 100%;
        font-size: 14px;
        color: #080707;
        letter-spacing: 0;
        padding: 10px 40px;
        box-sizing: border-box;
        .svg_box {
          margin-top: 10px;
          svg {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            cursor: pointer;
          }
        }
        .mailchimp {
          margin-bottom: 20px;
          width: 40px;
          margin-top: 10px;
          img {
            width: 100%;
            cursor: pointer;
          }
        }
        p:first-child {
          font-weight: bold;
          color: #333333;
          font-size: 14px;
          margin-top: 10px;
        }
        p:nth-child(2) {
          color: #333333;
          font-size: 14px;
          margin-top: 10px;
        }
        p:nth-child(4),
        p:nth-child(7) {
          color: #333333;
          font-size: 14px;
          margin-top: 25px;
          font-weight: bold;
        }
        p:nth-child(5),
        p:nth-child(8) {
          color: #666666;
          font-size: 14px;
          margin-top: 10px;
        }
        .slinkcontent {
          width: 100%;
          margin-top: 14px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          input {
            width: 85%;
            height: 36px;
            outline: none;
            border: 1px solid #dedcda;
            border-radius: 1px;
            border-right: none;
            padding-left: 13px;
          }
          button {
            width: 15%;
            height: 40px;
            border-left: none;
            outline: none;
            background: #2d6cfc;
            border-radius: 1px;
            font-size: 14px;
            color: #ffffff;
            letter-spacing: 0;
            cursor: pointer;
            border: 1px solid #dedcda;
          }
        }
        span {
          display: inline-block;
          margin-top: 19px;
          color: #2D6CFC;
          font-size: 14px;
          font-weight: bolder;
          .icon {
            display: inline-block;
          }
        }
      }
      .QRcode {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        justify-content: space-around;
        margin: 20px 0 20px 0;
        .left_box {
          width: 60%;
          p:first-child {
            font-weight: bold;
            color: #333333;
            font-size: 18px;
            margin-bottom: 10px;
          }
          .png_box {
            width: 400px;
            display: flex;
            border: 1px solid #ccc;
            margin: 10px 0;
            height: 36px;
            dl {
              margin: 0;
              height: 35px;
              display: flex;
              flex: 1;
              cursor: pointer;
              a {
                display: flex;
                dt,
                dd {
                  height: 36px;
                  margin: 0;
                  span {
                    font-size: 12px !important;
                    display: block;
                  }
                }
                svg {
                  margin: 6px 10px;
                  width: 20px;
                  height: 20px;
                }
              }
            }
            dl:first-child,
            dl:nth-child(2) {
              border-right: 1px solid #ccc;
            }
          }
        }
      }
    }
  }
}
</style>
